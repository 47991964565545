import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faHandHoldingUsd,
  faComments,
  faNetworkWired,
  faCog,
  faUserCircle,
  faCalendarAlt,
  faHome,
  faVideo,
  faNewspaper,
  faCubes,
} from '@fortawesome/free-solid-svg-icons';
import { MdPrivacyTip, MdAirplaneTicket } from 'react-icons/md';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { HiLightBulb } from 'react-icons/hi';
import { VscTasklist } from 'react-icons/vsc';
import { FaClipboardList } from 'react-icons/fa';
import { SidebarItem } from '../../types/SidebarItem';
import { Icon } from '../Icons';

export const SidebarData: SidebarItem[] = [
  {
    // MenuNavbar: true,
    title: 'Room',
    path: `/{{channel}}`,
    icon: <FontAwesomeIcon icon={faVideo} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Feed News',
    path: `/dashboard/feeds`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'feeds',
  },
  {
    // MenuNavbar: true,
    title: 'Lobby',
    path: `/lobby`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Lobby 3D',
    path: `/{{channel}}`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Acesso ao evento',
    path: `/plenaria`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Schedule',
    path: `/dashboard/schedule`,
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    key: 'schedule',
  },
  {
    // MenuNavbar: true,
    title: 'Speakers',
    path: `/dashboard/speakers`,
    icon: <FontAwesomeIcon icon={faComments} />,
    key: 'speakers',
  },
  {
    // MenuNavbar: true,
    title: 'Partners',
    path: `/dashboard/partners`,
    icon: <FontAwesomeIcon icon={faHandshake} />,
    key: 'partners',
  },
  {
    // MenuNavbar: true,
    title: 'Sponsors',
    path: `/dashboard/sponsors`,
    icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
    key: 'sponsors',
  },
  {
    // MenuNavbar: true,
    title: 'Content',
    path: `/dashboard/contents`,
    icon: <FontAwesomeIcon icon={faNewspaper} />,
    key: 'contents',
  },
  {
    // MenuNavbar: true,
    title: 'Products',
    path: `/dashboard/products`,
    icon: <Icon name="iconProduct" />,
    key: 'products',
  },
  {
    // MenuNavbar: true,
    title: 'Tips',
    path: `/dashboard/tips`,
    icon: <HiLightBulb size={20} color="#616161" />,
    key: 'tips',
  },
  {
    // MenuNavbar: true,
    title: 'Logistic',
    path: `/dashboard/logistic`,
    icon: <Icon name="iconLogistic" />,
    key: 'logistic',
  },
  // {
  //   // MenuNavbar: true,
  //   title: 'Survey',
  //   path: `/dashboard/surveys`,
  //   icon: <Icon name='iconSurveys'/>,
  //   key: 'survey',
  // },
  // {
  //   // MenuNavbar: true,
  //   title: 'Ask the speaker',
  //   path: `/dashboard/ask`,
  //   icon: <BsQuestionCircleFill size={20} color='#616161'/>,
  //   key: 'ask',
  // },
  // {
  //   // MenuNavbar: true,
  //   title: 'Satisfaction Search',
  //   path: `/dashboard/search`,
  //   icon: <Icon name='iconSatisfactionSearch'/>,
  //   key: 'links',
  // },
  {
    // MenuNavbar: true,
    title: 'Privacy',
    path: `/privacy-and-policy`,
    icon: <MdPrivacyTip size={20} color="#616161" />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Terms',
    path: `/terms-of-use`,
    icon: <VscTasklist size={20} color="#616161" />,
    key: 'default',
  },
  {
    title: 'My Data',
    path: `/profile`,
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    key: 'default',
  },
  // {
  //   title: 'Configurações',
  //   path: `/dashboard/settings`,
  //   icon: <FontAwesomeIcon icon={faCog} />,
  // },
  // {
  //   title: 'Configurações',
  //   path: `/dashboard/{{channel}}/settings`,
  //   icon: <FontAwesomeIcon icon={faCog} />,
  // },
];
